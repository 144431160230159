<template>
    <div class="signup_content">
        <img src="@/assets/images/interact_video/signup.png" alt="" @click="changeIsSignupVisible">
          <div class="signup_form" v-show="isSignupVisible">
            <h3>{{ item.title }}</h3>
            <div class="signup_des">{{ `报名截止时间：${item.activityDate}`}}</div>
            <div v-if="!isSignupInputEnd" class="signup_input">
              <el-input v-model="signupName"  placeholder="请输入姓名" v-if="item.nameStatus === '1'"></el-input>
              <el-input v-model="signupMobile" placeholder="请输入手机号" ></el-input>
              <el-button type="primary" @click.stop="submitSignup(item)">{{ item.buttonText || '提交报名'}}</el-button>
            </div>
            <div v-else class="signupSuccess">
              <img src="@/assets/images/signupSuccess.svg" alt="">
              <span>恭喜您，报名成功~</span>
            </div>
          </div>
    </div>
</template>
<script>
export default {
    props: {
        video: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        item: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        stUserInfo: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
    },
    data: function() {
        return {
            isSignupVisible: false, // 是否展示报名框
            isSignupInputEnd: false, // 是否已提交报名
            signupName: "", //填写姓名
            signupMobile: "", //填写电话
        }
    },
    methods: {
        changeIsSignupVisible() {
            this.isSignupVisible = !this.isSignupVisible
            // this.isVoteVisible = false
            // this.$set(this.cardSettings, "isCardExpanded", false);
            //     if (this.isSignupVisible) {
            //         this.player.pause();
            // }
        },
        // 提交报名
        async submitSignup() {
            await this.validSingup();
            if (this.stUserInfo.iAccountId) {
                this.API.dataStatistics.userSignUp({
                phoneNumber: this.signupMobile,
                userName: this.signupName || undefined,
                videoId: this.video.iId,
                signUpInfoId: this.item.id,
                userId: this.stUserInfo.iAccountId,
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.isSignupInputEnd = true;
                    } else {
                        Notify({ type: "danger", message: "报名失败" });
                    }
                })
            }
        },
        //验证报名
        validSingup() {
            return new Promise((resolve) => {
                if (this.signupMobile == "") {
                    this.$toast.fail({ message: `请填写电话`, closeOnClick: true });
                    return;
                }
                if (!this.isValidMobile(this.signupMobile)) {
                    this.$toast.fail({ message: `电话输入有误`, closeOnClick: true });
                    return;
                }
                resolve();
            });
        },
        // 验证手机号
        isValidMobile(mobile) {
            var myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
            return myreg.test(mobile);
        },
    },
}
</script>
<style lang="less" scoped>
.signup_content {
    position: relative;
    margin-top: 12px;
    > img {
        width: 40px;
        align-self: flex-end;
    }
    .signup_form {
        position: absolute;
        right: 0;
        top: 48px;
        width: 300px;
        padding: 12px;
        background: #FFFFFF;
        opacity: 0.93;
        border-radius: 6px;
        transition: all 0.5s ease;
        z-index: 7;
        .signup_des {
            font-size: 12px;
            color: #8D8DA7;
            margin-top: 8px;
            margin-bottom: 12px;
        }
        .signup_input {
            > div {
                margin-bottom: 12px;
            }
        }
        .signupSuccess {
            display: flex;
            flex-direction: column;
            align-items: center;
            > img {
                width: 76px;
            }
            > span {
                margin-top: 12px;
                font-weight: bold;
                color: #FFA01D;
            }
        }
    }
}
</style>
