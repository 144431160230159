<template>
    <div class="vote_content">
        <img src="@/assets/images/interact_video/vote.png" alt="" @click="changeIsVoteVisible">
        <div class="vote_form" v-show="isVoteVisible">
            <h3>{{ item.title }}</h3>
            <div class="vote_des">{{ `投票截止时间：${item.activityDate}`}}</div>
            <div v-if="!isVoteInputEnd" class="vote_input">
                <div class="single_choice" v-if="item.singleSelected === 0">
                    <van-radio-group v-model="radio">
                        <van-radio
                        v-for="(value, index) in Object.keys(item.option)"
                        :key="index"
                        :name="value"
                        v-model="radio"
                        >
                        {{ value }}
                        </van-radio>
                    </van-radio-group>
                    <van-button type="primary" @click="ballot()">提交</van-button>
                </div>
                <div class="multiple_choice" v-else>
                    <van-checkbox-group v-model="result">
                        <van-checkbox
                        v-for="(value, index) in Object.keys(item.option)"
                        :key="index"
                        :name="value"
                        shape="square"
                        >
                        {{ value }}
                        </van-checkbox>
                    </van-checkbox-group>
                    <van-button type="primary" @click="ballot()">提交</van-button>
                </div>
            </div>
            <div v-else class="voteSuccess">
                <div class="v_option" v-for="(value, index) in Object.keys(dataSource)" :key="index">
                    <div class="option_row">
                        <b>{{ value }}</b>
                        <span>{{ dataSource[value] }}票 {{ getProportion(dataSource[value], dataSource) }}</span>
                    </div>
                    <div class="option_bar">
                        <span :style="{ width: getProportion(dataSource[value], dataSource) }"> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { toPercent } from '@/utils/utils'

export default {
     props: {
        video: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        item: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        stUserInfo: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
    },
    data: function() {
        return {
            isVoteVisible: false, // 是否展示投票框
            isVoteInputEnd: false, // 是否已提交投票
            radio: "", // 投票单选数据
            result: [], //投票多选数据
            dataSource: {},
        }
    },
    methods: {
        // 投标占比
        getProportion(value, dataSource) {
            if (dataSource instanceof Object && Object.keys(dataSource).length > 0) {
                const numArr = Object.keys(dataSource).map((item) => {
                    return dataSource[item]
                })
                const reducer = (previousValue, currentValue) => previousValue + currentValue;
                const total = numArr.reduce(reducer)
                return toPercent(total > 0 ? value/total : 0, 0)
            }
        },
        changeIsVoteVisible() {
            this.isVoteVisible = !this.isVoteVisible
            // this.isSignupVisible = false
            // this.$set(this.cardSettings, "isCardExpanded", false);
            // if (this.isVoteVisible) {
            //     this.player.pause();
            // }
        },
        // 投票提交
        ballot() {
            if (this.stUserInfo.iAccountId) {
                this.API.dataStatistics.userVoting({
                    optionRes: this.item.singleSelected == 0 ? [this.radio] : this.result,
                    videoId: this.video.iId,
                    voteInfoId: this.item.id,
                    userId: this.stUserInfo.iAccountId,
                }).then((res) => {
                    if (res && res instanceof Object && res.code === 200) {
                        this.dataSource = JSON.parse(res.data.optionArray)
                        this.isVoteInputEnd = true;
                    } else {
                        Notify({ type: "danger", message: "投票失败" });
                    }
                })
            }
        },
    },
}
</script>

<style lang="less" scoped>
.vote_content {
    position: relative;
    margin-top: 12px;
    > img {
        width: 40px;
        align-self: flex-end;
    }
    .vote_form {
        position: absolute;
        right: 0;
        top: 48px;
        width: 300px;
        padding: 12px;
        background: rgba(255, 255, 255, 0.93);
        border-radius: 6px;
        z-index: 7;
        transition: all 0.5s ease;
        .vote_des {
            font-size: 12px;
            color: #8D8DA7;
            margin-top: 8px;
            margin-bottom: 12px;
        }
        // 投票样式
        .vote_input {
            // 单选投票样式
            .single_choice {
                .van-radio {
                    flex-direction: row-reverse;
                    width: 100%;
                    justify-content: space-between;
                    background: #fff;
                    margin-bottom: 12px;
                    padding: 10px 12px 10px 4px;
                    border-radius: 6px;
                    .van-radio__icon--checked .van-icon {
                    background: rgba(255, 211, 26, 1);
                    border-color: rgba(255, 211, 26, 1);
                    }
                }
                .van-radio[aria-checked="true"] {
                    background: #FF9226;
                }
            }
            // 多选投票样式
            .multiple_choice {
                .van-checkbox {
                    flex-direction: row-reverse;
                    width: 100%;
                    justify-content: space-between;
                    background: #fff;
                    margin-bottom: 12px;
                    padding: 10px 12px 10px 4px;
                    border-radius: 6px;
                    .van-checkbox__icon--checked .van-icon {
                    background: rgba(255, 211, 26, 1);
                    border-color: rgba(255, 211, 26, 1);
                    }
                }
                .van-checkbox[aria-checked="true"] {
                    background: #FF9226;
                }
            }
            // 投票确定按钮样式
            .van-button--primary {
                background-color: #FF9226;
                border: 1px solid #FF9226;
                border-radius: 16px;
                box-shadow: 0px 3px 6px rgba(255, 146, 38, 0.4);
            }
            .van-button {
                height: 32px;
            }
        }
        .voteSuccess {
            display: flex;
            flex-direction: column;
            align-items: center;
            .v_option {
                margin-bottom: 10px;
                width: 100%;
                .option_row {
                    display        : flex;
                    justify-content: space-between;
                    margin-bottom  : 5px;
                    align-items: center;
                    b {
                        font-weight: normal;
                        flex: 1;
                        color: #1A1A34;
                    }
                    span {
                        color    : #8D8DA7;
                        font-size: 12px;
                        width: 22%;
                        text-align: right;
                    }
                }
                .option_bar {
                    height    : 12px;
                    background: #E2E2EA;
                    span {
                        height        : 12px;
                        display       : inline-block;
                        vertical-align: top;
                        width         : 0;
                        background    : #FEA644;
                    }
                }
            }
        }
    }
}
</style>