<template>
  <div :class="{ video_holder: true, isBranchStoped: isBranchStoped }">
    <video
      ref="videoPlayer"
      class="video-js skin-mask"
      width="300px"
      height="300px"
      playsinline="true"
      webkit-playsinline="true"
    />

    <!--底部的渐变蒙层-->
    <div class="black_gradient"></div>

    <!--全屏控制播放与暂停-->
    <div class="moniter"
      v-if="playIn == 'mobile'"
      v-onSingleTap="singleTap"
      v-onDoubleTap="doubleTap"
      @click="singleTap"
    >
    </div>
    <div class="moniter" v-if="playIn == 'pc'" @click="singleTap"></div>

    <div
      :id="`bigLike${optionsIndex}`"
      :class="{
        big_like: true,
        animate__animated: true,
        animate__bounceIn: bigLike.isIn,
        animate__zoomOut: bigLike.isOut,
      }"
      :style="{ left: bigLike.x, top: bigLike.y }"
    ></div>

    <!--在线客服-->
    <!-- <div class="online_service" @click="jumpOnlineService" v-if="!isShareMode">
      <i class="icon_service"> </i>
      <span>在线客服</span>
    </div> -->

    <!-- 点赞/收藏 -->
    <div class="sideFix">
        <div class="a_user">
        <img :src="author.avatar?author.avatar:''" alt="avatar" @click.stop="toUserPage" />
        <img v-if="video.isAttention!=undefined" :src="options.video.isAttention?ico_unfocus:icoFocus" class="add" @click.stop="actionFocus">
      </div>
      <div class="a_action" @click.stop="actionLike" v-if="!isShareMode">
        <div :class="{ action: true, on: video.iCheckLike }">
          <i class="ico_zan"></i><span>{{ video.iLikeNum }}</span>
        </div>
        <div class="action" @click.stop="actionShare">
          <i class="ico_share"></i><span>{{ video.iShareNum }}</span>
        </div>
      </div>
    </div>

    <!--视频标题-->
     <!-- v-onSingleTap="singleTap" v-onDoubleTap="doubleTap" -->
    <!-- <div class="video_title" v-if="playIn == 'mobile' && !isInBranch">
      <Unfold  :data="video.sDes" :maxLen="44" />
    </div>
    <div class="video_title" v-if="playIn == 'pc'&& !isInBranch">
      <Unfold  :data="video.sDes" :maxLen="44" />
    </div> -->

    <!--底部作者栏-->
    <div class="author_row" @click="togglePlayer">
        <div class="subTitle" v-if="video.sTitle">
            {{video.sTitle}}
        </div>
        <div class="des" v-if="video.sDes">
            {{video.sDes}}
        </div>
    </div>

    <!-- 名片按钮 -->
    <InitCard v-if="cardSettings.isShowCard && !isIframeVisible && !isInBranch" :video="video" :stUserInfo="stUserInfo" :cardSettings="cardSettings" />
    <!-- 视频分支按钮 -->
    <!-- <div class="interact_btns" v-if="stopBtnsSettings.length > 0">
      <div v-for="(item, index) in stopBtnsSettings" :key="index">
        <transition :name="`my${index + 1}`" tag="span" appear>
          <span @click="gotoBranch(item)">{{ item.label }}</span>
        </transition>
      </div>
      <transition
        :name="`my${stopBtnsSettings.length + 1}`"
        tag="span"
        appear
        v-if="branches[branchIndex].stopTime != 'end'"
      >
        <span @click="continueMaster()">继续播放</span>
      </transition>
      <transition
        :name="`my${stopBtnsSettings.length + 1}`"
        tag="span"
        appear
        v-if="branches[branchIndex].stopTime == 'end'"
      >
        <span @click="replay()">重新播放</span>
      </transition>
    </div> -->
    <div class="return_to_master" v-if="isInBranch" @click="returnToMaster()">返回</div>
    <!-- mix -->
    <div :class="{ interact_mix: true, isMixVisible: isMixVisible, isIframeVisible: isIframeVisible }" v-if="mixSettings.nodes.length > 0 && !isInBranch">
      <!-- <i class="pop_close" @click.stop="mixClose"></i> -->
      <div v-for="(item, index) in mixSettings.nodes.filter(value => ['signup', 'vote'].indexOf(value.mixType) == -1)" :key="index" v-show="!isIframeVisible">
        <div class="goods_box" @click="openUrl(item)" v-if="item.mixType == 'jumpUrl'">
          <div class="goods_img"><img :src="item.image" alt="" /></div>
          <div class="goods_txt">
            <strong>{{ item.title }}</strong
            ><span>{{ item.desc }}</span>
          </div>
        </div>
        <div :class="`iframe_box ${item.class}`" @click="iframeBoxClick(item)" v-if="item.mixType == 'iframe'">
          <div class="iframe_img"></div>
          <div class="iframe_txt">{{ item.title }}</div>
        </div>
        <div class="image_box" @click="imageBoxClick(item)" v-if="item.mixType == 'image'">
          <div class="image_img"></div>
          <div class="image_txt">{{ item.title }}</div>
        </div>
        <div class="app_jump" @click="openUrl(item)" v-if="item.mixType == 'appJump'">{{ item.title }}</div>
      </div>
      <div class="iframe_layer" @click.stop="mixClose" v-show="isIframeVisible"></div>
      <iframe :src="iframeUrl" frameborder="0" v-show="isIframeVisible"></iframe>
    </div>
    <!-- 投票、报名 -->
    <div
      :class="{ vote_signup_mix: true, isMixVisible: isMixVisible }"
      v-if="mixSettings.nodes.filter(value => ['signup', 'vote'].indexOf(value.mixType) > -1).length > 0 && !isInBranch"
    >
      <div v-for="(item, index) in mixSettings.nodes.filter(value => ['signup', 'vote'].indexOf(value.mixType) > -1)" :key="index">
        <InitSignup v-if="item.mixType == 'signup' && isAbort(item.activityDate)" :item="item" :video="video" :stUserInfo="stUserInfo" />
        <InitVote v-if="item.mixType == 'vote' && isAbort(item.activityDate)" :item="item" :video="video" :stUserInfo="stUserInfo" />
      </div>
    </div>
    <!-- 分支视频 -->
    <div
      :class="{ branch_video_mix: true }"
      v-if="mixSettings.nodes.filter(value => ['branchVideo'].indexOf(value.mixType) > -1).length > 0 && !isInBranch"
    >
      <div>
        <span
          v-for="(item, index) in mixSettings.nodes.filter(value => ['branchVideo'].indexOf(value.mixType) > -1)"
          :key="index"
          @click="gotoBranch(item)"
        >
          {{ item.title }}
        </span>
      </div>
    </div>
    <!-- image layer -->
    <div class="image_layer" @click.stop="mixClose" v-show="isImageVisible"><img :src="imageUrl" alt="" /></div>
  </div>
</template>
<script>
// import VConsole from "vconsole";
import { ImagePreview, Notify } from "vant";
import videojs from "video.js";
import { sleep , debounce, playerArray} from "@/utils/utils.js";
import Unfold from '../unfold/index.vue'
import InitSignup from './components/initSignup/index.vue'
import InitVote from './components/initVote/index.vue'
import InitCard from './components/initCard/index.vue'
import icoFocus from '../../assets/images/interact_video/ico_focus.png'
import ico_unfocus from '../../assets/images/interact_video/ico_unfocus.png'
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    playIn: {
      type: String,
      default() {
        return ""; // mobile,pc
      },
    },
    optionsIndex: {
      type: Number,
      default() {
        return 1;
      },
    },
    isShareMode: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  components: {
    Unfold,
    InitSignup,
    InitVote,
    InitCard
  },
  watch: {
    options: function (oldOptions, newOptions) {
      this.handleVideoOptions();
      if (this.player) {
        this.player.poster(this.options.video.poster);
        this.player.src(this.options.video.src);
        this.player.load();
        // this.reset()
        this.branchIndex = 0;
      } else {
        this.initVideoPlayer();
      }
    },
  },
  data() {
    return {
      icoFocus:icoFocus,
      ico_unfocus:ico_unfocus,
      audio5js: null, //音频播放器
      player: null, //播放器句柄
      author: {
        avatar: "",
        name: "",
      },
      bigLike: {
        isIn: false, //大爱心进入
        isOut: false, //大爱心离开
        x: 0,
        y: 0,
      },
      video: {
        sDes:''
      },
      stopCount: 0, //停下记数，只有当第二次停下，也就是拖动时，才会触发whenstop重新调整branchIndex
      isContinuing: false, //继续播放状态，维持2秒，避免重复触发
      isBranchStoped: false, //是否为分支强制暂停
      isBackFromBranch: false, //是否从分支视频到主视频
      isInBranch: false, //是否在分支视频里
      stopBtnsSettings: [],
      branchIndex: 0, //代表下一个停下来的分支索引，播放时200ms判断一次当前时间是否大于了下一个分支时间，大于则停下来
      // duration: 0, //视频总时长
      seedingRate: false, // 视频是否播放完成
      visitBranch: false, // 是否访问过分支视频
      branches: [], //分支
      playRate: 1, //播放速度，默认为1
      isVideoSwitched: false, //切视频标记，每次切视频都会变成true
      //名片配置
      cardSettings: {
        isShowCard: false, //是否展示名片
        name: "",
        title: "",
        corpName: "",
        corpLogo: "",
        mobile: "",
      },
      isMixVisible: false, //iframe浮层是否可见
      isMixClosed: false, //标记mix是否被用户手动关闭
      isIframeVisible: false, //iframe内容是否可见
      iframeUrl: "", //iframe链接
      isImageVisible: false, //图片内容是否可见
      imageUrl: "", //image链接
      mixSettings: {
        duration: 99999, //持续秒数
        nodes: [],
      },
      stUserInfo: null,
    };
  },
  methods: {
    updateAccountInfo(info){
      console.log("updateAccountInfo ",info);
      this.stUserInfo = info
    },
    singleTap() {
      this.$emit("singleTap");
    },
    doubleTap(e) {
      this.$emit("doubleTap", e);
    },
    // 是否大于当前时间
    isAbort(value) {
      return this.$moment().valueOf() < this.$moment(value).valueOf()
    },
    //跳小程序
    jumpOnlineService() {
      // console.log(beacon);
      // location.href="beacon://goMiniApp?channel=gpdt&miniAppType=dtActivity4"
      beacon.openMiniApp({
        path: "pages/dtActivity4/dtActivity4",
      });
    },
    //切换播放器的暂停和播放
    togglePlayer() {
      if (this.player.paused()) {
        this.player.play();
      } else {
        this.player.pause();
      }
    },
    //双击后，大爱心显示，过一阵子消失
    async moniterDoubleClick(e) {
      if (!this.video.iCheckLike) {
        this.actionLike();
      }
      this.bigLike.isIn = true;
      this.bigLike.isOut = false;
      this.bigLike.x = e.changedTouches[0].pageX + "px";
      this.bigLike.y = e.changedTouches[0].pageY + "px";
      await sleep(1200);
      this.bigLike.isOut = true;
    },
    toUserPage:debounce(function(){
      this.player.pause();
      this.$toNewPage("/user",{id:this.author.authorId},true);
    }),
    //关注，取消关注
    async actionFocus() {
      if (this.playIn == "pc") {
        return;
      }
      console.log("actionFocus",this.$store.state.login.stUserInfo);
      if (!this.$store.state.login.stUserInfo||!this.$store.state.login.stUserInfo.AID || this.$store.state.login.stUserInfo.AID <= 0) {
        this.player.pause();
        new Promise((resolve, reject) => {
          beacon.openLoginPanel({}, (data) => {
            resolve(data);
          });
        });
        return;
      }
      const iAction = this.video.isAttention?0:1;
      const params = {
        userId: this.$store.state.login.stUserInfo.AID,
        attentionUserId: this.author.authorId,
        isAttention: iAction,
      };
      // console.log("clickFocusAutor",JSON.stringify(params));
      const data = await this.API.appSlider.clickFocusAutor(params);
      // console.log("clickFocusAutor result ",data);
      if (data.data.code===200) {
        if (iAction == 1) {
          this.video.isAttention = 1;
        } else {
          this.video.isAttention = 0;
        }
        this.$emit("changeFocus",this.video)
      }
      
    },
    //点赞
    async actionLike() {
      if (this.playIn == "pc") {
        return;
      }
      if (!this.$store.state.login.stUserInfo||!this.$store.state.login.stUserInfo.AID || this.$store.state.login.stUserInfo.AID <= 0) {
        this.player.pause();
        new Promise((resolve, reject) => {
          beacon.openLoginPanel({}, (data) => {
            resolve(data);
          });
        });
        return;
      }
      const iAction = this.video.iCheckLike ? -1 : 1; //1点赞，-1取消点赞
      const params = {
        stUserInfo: {iAccountId:this.$store.state.login.stUserInfo.AID},
        iId: this.video.iId,
        iAction: iAction,
      };
      // console.log("clickLikeVideo",JSON.stringify(params));
      const data = await this.API.appSlider.clickLikeVideo(params);
      // console.log(data);
      if (data.status==200) {
        if (iAction == 1) {
          this.video.iCheckLike = 1;
          this.video.iLikeNum += 1;
        } else {
          this.video.iCheckLike = 0;
          this.video.iLikeNum -= 1;
        }
      }
      
    },
    async actionShare() {
      if (this.playIn == "pc") {
        return;
      }
      var optionArray = [];
      var optionDefault = {
        shareType: "default",
        title: this.video.sTitle,
        desc: "",
        imgUrl: "http://res.idtcdn.com/beacon/images/icon.jpg",
        link: "https://dtinteraction.mdengta.com/dengYingSingle?id=" + this.video.iId,
      };
      optionArray.push(optionDefault);
      beacon.showSharePanel(optionArray, function () {});
      //上报转发
      const params = {
        stUserInfo: this.stUserInfo,
        iId: this.video.iId,
      };
      const data = await this.API.appSlider.clickShareVideo(params);
      this.video.iShareNum += 1
    },
    goto(seconds) {
      this.player.currentTime(seconds);
      // console.log(this.player.currentTime());
    },
    play() {
      this.player.play();
    },
    pause() {
      this.player.pause();
    },
    reset() {
      this.branchIndex = 0;
      this.branches = [];
      this.isBranchStoped = false;
      this.stopBtnsSettings = [];
      this.isMixVisible = false;
    },
    // =============== 视频配置 ========
    //处理视频配置
    handleVideoOptions() {
      //记录传过来的app用户信息
      this.stUserInfo = this.options.stUserInfo || {};
      //合成video.js需要用到的参数
      this.videoOptions = Object.assign({}, this.options, {
        controls: true,
        preload: "auto",
        playsinline: true,
        // playbackRates: [1, 1.5],
        poster: this.options.video.poster,
        sources: [
          {
            src: this.options.video.src,
            type: "video/mp4",
          },
        ],
        inactivityTimeout: this.playIn == "mobile" ? 2000 : 0,
        userActions: {
          hotkeys: (event) => {
            // console.log("event");
            const currentTime = this.player.currentTime();
            if (event.code == "ArrowRight") {
              this.player.currentTime(currentTime + 3);
            } else if (event.code == "ArrowLeft") {
              this.player.currentTime(currentTime - 3);
            }
          },
        },
      });
      // console.log("videoOptions",this.videoOptions)
      this.branches = this.options.branches;
      if (this.videoOptions.cardSettings != undefined) {
        this.cardSettings = Object.assign({}, this.cardSettings, this.videoOptions.cardSettings)
      }
    },
    //初始化播放器
    initVideoPlayer() {
      if (this.videoOptions.src == "" || this.player != null) {
        return;
      }
      // console.log(this.optionsIndex, "this.videoOptions", this.videoOptions);
      //videojs初始化
      this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
      this.video = this.videoOptions.video || {};
      this.author = this.videoOptions.author || {};
      // console.log(this.video, this.user);
      //可以播了
      this.player.on("ready", () => {
        //
      });

      //视频时长获取到后，如果发现是从分支回来的，则需把时间轴滑动到上一次节点位置，静音后播放，否则视频没有画面
      this.player.on("loadedmetadata", (e) => {
        // console.log('loadedmetadata',e);
        //master的分支如果有end，处理成准确的时长
        this.handleMasterBranches();

        if (this.isBackFromBranch) {
          // console.log("this.branchIndex", this.branchIndex);
          this.player.currentTime(this.branches[this.branchIndex].realStopTime - 0.1);
          this.isBackFromBranch = false;
          this.player.muted(false);
          this.player.play();
        } else {
          if (this.optionsIndex == 0) {
            this.player.play();
          }
        }
      });
      //开始播了
      this.player.on("play", () => {
        // console.log("play");
        this.stopCount = 0;
      });
      //暂停了
      this.player.on("pause", () => {
        // console.log("pause");
        this.stopCount += 1;
      });
      //播放速度改变了
      this.player.on("ratechange", (e) => {
        if (this.isVideoSwitched) {
          this.player.playbackRate(this.playRate);
          this.isVideoSwitched = false;
        } else {
          this.playRate = this.player.playbackRate();
        }
      });
      /*
        timeupdate 的特性，非常重要：
        1. 300ms以内会给一次回调
        2. 播完的时候，给出的当前时间会大于视频总时长最多300ms
      */
      this.player.on("timeupdate", async (e) => {
          if (this.stopCount) {
            this.stopCount += 1;
          }
          const currentTime = this.player.currentTime();
          const endTime = this.player.duration()
          if (this.isInBranch) {
            if (endTime === currentTime) {
              this.returnToMaster()
            }
            return;
          }
          // 视频剩余时长小于或等于4秒，表示视频播放完成
          if (endTime - 4 <= currentTime && !this.seedingRate) {
            this.seedingRate = true
          }
          //监听播放过程中到达分支点，执行对应动作
          this.updateBranchIndexWhenPlaying(currentTime);
          //监听拖动过程中的索引值变化
          this.updateBranchIndexWhenStop(currentTime);
      });
    },
    /*
      master的分支如果有end，处理成准确的时长
      接着容错处理，把非阻塞的互动的duration的最大值处理成下一个节点的时间点的前一秒
    */
    handleMasterBranches() {
      if (!this.isInBranch) {
        const videoDuration = this.player.duration();
        //把end处理成准确的时长
        if (this.branches == undefined || this.branches.length == 0) {
          return;
        }
        this.branches.map((item) => {
          item.realStopTime = item.stopTime;
          if (item.stopTime == "end" || item.stopTime > videoDuration) {
            item.realStopTime = videoDuration;
          } else {
            item.realStopTime *= 1;
            if (item[item.interactType + "Settings"].duration != undefined) {
              item[item.interactType + "Settings"].duration *= 1;
            }
          }
          return item;
        });
        // console.log("this.branches", this.branches);
        //处理duration容错，假如设定的duration超过了下一个节点，则强制设置为下一个节点的前一秒
        for (var i = 0; i < this.branches.length; i++) {
          const item = this.branches[i];
          let nextItem;
          if (i < this.branches.length - 1) {
            nextItem = this.branches[i + 1];
          }
          if (item.interactType != "stopBtns") {
            if (nextItem && item.realStopTime + item[item.interactType + "Settings"].duration > nextItem.realStopTime) {
              item[item.interactType + "Settings"].duration = nextItem.realStopTime - item.realStopTime - 1;
            }
          }
        }
        // console.log("this.branches", this.branches);
      }
    },
    //监听播放过程中到达分支点，执行对应动作
    updateBranchIndexWhenPlaying(currentTime) {
      // console.log("this.stopCount", this.stopCount);
      if (
        this.branches == undefined ||
        this.branches.length == 0 ||
        this.stopCount ||
        this.branchIndex == 9999 ||
        this.isInBranch
      ) {
        return;
      }
      const currentBranch = this.branches[this.branchIndex];
      const realStopTime = currentBranch.realStopTime;
      const interactType = currentBranch.interactType;
      const isEndBranch = this.branchIndex == this.branches.length - 1;
      let duration = 0;
      if (interactType != "stopBtns") {
        duration = currentBranch[interactType + "Settings"].duration;
      }
      let nextStopTime;
      if (this.branches[this.branchIndex + 1]) {
        nextStopTime = this.branches[this.branchIndex + 1].realStopTime;
      }
      // console.log(!isEndBranch, currentTime, nextStopTime, interactType == "stopBtns");
      // 分支视频的触发逻辑是过了停下来的那个点
      if (
        ((!isEndBranch && currentTime >= realStopTime - 0.4 && currentTime < nextStopTime) ||
          (isEndBranch && currentTime >= realStopTime)) &&
        interactType == "stopBtns"
      ) {
        this.handleStopBtns(currentTime, currentBranch);
        // 浮动元素的触发逻辑是处于duration时间段内
      } else if (
        ((!isEndBranch && currentTime + 0.4 >= realStopTime && currentTime < realStopTime + duration) ||
          (isEndBranch && currentTime >= realStopTime)) &&
        interactType != "stopBtns"
      ) {
        if (interactType == "mix" && !this.isMixClosed && !this.isMixVisible) {
          this.handleMix(currentBranch.mixSettings);
        }
        //最后一个节点会保持悬浮，忽略duration设置
      } else if (!isEndBranch && currentTime >= realStopTime + duration && interactType != "stopBtns") {
        this.branchIndex += 1;
        this.isMixVisible = false;
      } else if (!isEndBranch && currentTime >= nextStopTime && interactType == "stopBtns") {
        // console.log("in");
        this.branchIndex += 1;
      }
      // console.log("updateBranchIndexWhenPlaying", this.branchIndex);
    },
    //更新拖动过程中的索引值变化
    async updateBranchIndexWhenStop(currentTime) {
      // console.log('this.stopCount',this.stopCount);
      if (this.stopCount < 2 || this.isBackFromBranch) {
        return;
      }
      const isFound = await this.getBranchIndexForNoStopBtns(currentTime);
      // console.log("isFound", isFound);
      if (!isFound) {
        this.getBranchIndexForStopBtns(currentTime);
      }
      if (this.branches.length == 0) {
        return;
      }
      const currentBranch = this.branches[this.branchIndex];
      let duration;
      if (currentBranch.interactType != "stopBtns") {
        duration = currentBranch[currentBranch.interactType + "Settings"].duration;
      }
      // console.log("currentInteractType", currentBranch);
      if (
        duration &&
        this.player.currentTime() > currentBranch.realStopTime &&
        this.player.currentTime() < currentBranch.realStopTime + duration
      ) {
        if (currentBranch.interactType == "mix") {
          this.handleMix(currentBranch.mixSettings);
        }
      } else {
        this.isMixVisible = false;
      }
    },
    //判断浮层节点： 当前时间落在上一个节点结束时间（如果是停止节点是本身，如果是浮动节点是本身+duration）和duration之间
    getBranchIndexForNoStopBtns(currentTime) {
      return new Promise((resolve, reject) => {
        for (let i = 0; i < this.branches.length; i++) {
          const item = this.branches[i];
          let lastItem;
          let lastTime;
          if (i > 0) {
            lastItem = this.branches[i - 1];
            if (lastItem.interactType == "stopBtns") {
              lastTime = item.realStopTime;
            } else {
              lastTime = lastItem.realStopTime + lastItem[lastItem.interactType + "Settings"].duration;
              // console.log("lastTime", lastTime);
            }
          }
          if (
            i == 0 &&
            currentTime <= item.realStopTime + item[item.interactType + "Settings"].duration &&
            item.interactType != "stopBtns"
          ) {
            this.branchIndex = i;
            resolve(true);
            break;
          } else if (
            i > 0 &&
            currentTime >= lastTime &&
            currentTime <= item.realStopTime + item[item.interactType + "Settings"].duration &&
            item.interactType != "stopBtns"
          ) {
            this.branchIndex = i;
            resolve(true);
            break;
          }
        }
        resolve(false);
      });
    },
    //判断停止节点：判断当前时间落在上一个节点和当前节点之间
    getBranchIndexForStopBtns(currentTime) {
      for (let i = 0; i < this.branches.length; i++) {
        const item = this.branches[i];
        let lastItem;
        if (i > 0) {
          lastItem = this.branches[i - 1];
        }
        let nextItem;
        if (i < this.branches.length - 1) {
          nextItem = this.branches[i + 1];
        }
        // if (i > 0) {
        //   console.log(i, currentTime, item.realStopTime);
        // }
        if (i == 0 && currentTime < item.realStopTime && item.interactType == "stopBtns") {
          this.branchIndex = i;
          break;
        } else if (i > 0 && currentTime + 0.4 >= item.realStopTime && item.interactType == "stopBtns") {
          if (i == this.branches.length - 1) {
            this.branchIndex = i;
          } else {
            this.branchIndex = i + 1;
          }
          break;
        }
      }
    },

    //================stopbtns 分支视频================
    //处理停下来的按钮选择
    handleStopBtns(currentTime, currentBranch) {
      if (currentTime > currentBranch.realStopTime + 0.3 || this.isContinuing) {
        return;
      }
      this.player.pause();
      this.isBranchStoped = true;
      this.stopBtnsSettings = [];
      this.stopBtnsSettings = currentBranch.stopBtnsSettings;
    },
    //主险继续播
    async continueMaster() {
      this.player.muted(false);
      this.player.play();
      this.stopBtnsSettings = [];
      this.isBranchStoped = false;
      this.isInBranch = false;
      this.isContinuing = true;
      await sleep(2 * 1000);
      this.isContinuing = false;
      // this.branchIndex = this.branchIndex < this.branches.length - 1 ? this.branchIndex + 1 : 9999;
    },
    //重新播放
    replay() {
      this.player.currentTime(0);
      this.player.muted(false);
      this.player.play();
      this.branchIndex = 0;
      this.isBranchStoped = false;
      this.stopBtnsSettings = [];
      this.isGoodsClosed = false;
      this.isIframeClosed = false;
    },
    //跳视频分支
    gotoBranch(item) {
      this.player.muted(false);
      this.player.src(item.src || item.url);
      this.player.play();
      this.stopBtnsSettings = [];
      this.isBranchStoped = false;
      this.isInBranch = true; // 当前是否在分支视频标识
      this.visitBranch = true; // 进入过分支视频标识
      this.isVideoSwitched = true;
    },
    //跳视频master
    returnToMaster() {
      this.isBackFromBranch = true;
      this.isVideoSwitched = true;
      this.player.src(this.videoOptions.sources[0].src);
      this.isInBranch = false;
      this.stopBtnsSettings = this.branches[this.branchIndex].stopBtnsSettings;
    },

    //================mix ================
    // mix类型元素埋点
    pointMix(value) {
      // type 1.开户2.商品3.行情4.工具
      const typeObj = {
        account: 1,
        good: 2,
        market: 3,
        tool: 4,
      }
      // 只有登陆后才记录埋点
      if (this.stUserInfo.AID && typeObj[value.realType]) {
        this.API.dataStatistics.commonBuriedPoint({
          videoId: this.video.iId,
          userId: this.stUserInfo.AID,
          businessId: value.id,
          businessName: value.title,
          type: typeObj[value.realType],
        })
      }
    },
    //处理mix
    handleMix(mixSettings) {
      this.mixSettings = mixSettings;
      this.isMixVisible = true;
      this.isMixClosed = false;
      // const mixType = mixSettings.nodes[0].mixType;
      // if (!mixSettings.isSoundPlayed) {
      //   // this.playSound(mixType);
      //   mixSettings.isSoundPlayed = true;
      // }
    },
    //iframe box点击
    iframeBoxClick(item) {
      this.iframeUrl = item.url;
      this.isIframeVisible = true;
      this.player.pause();
      this.isBranchStoped = true;
    },
    //image box点击
    imageBoxClick(item) {
      this.player.pause();
      this.isBranchStoped = true;
      if (this.playIn == "mobile") {
        const _this = this;
        ImagePreview({
          images: [item.url],
          showIndex: false,
          onClose() {
            _this.isImageVisible = false;
            _this.isMixClosed = true;
            _this.player.play();
            _this.isBranchStoped = false;
          },
        });
      } else if (this.playIn == "pc") {
        this.isImageVisible = true;
        this.imageUrl = item.url;
      }
    },
    //购物点击
    openUrl(item) {
      this.player.pause();
      // 埋点jumpUrl和appJump
      this.pointMix(item)
      const finalUrl = this.getFinalUrl(item.url);
      if (this.playIn == "mobile") {
        location.href = finalUrl;
      } else if (this.playIn == "pc") {
        window.open(finalUrl);
      }
    },
    //如果是分享出来播放的，互动元素跳转需要做一些适配，发现跳app的，转成跳下载app
    getFinalUrl(url) {
      let result = url;
      if (this.isShareMode) {
        if (url.indexOf("dt_from=app") > -1 || url.indexOf("beacon://") > -1) {
          result = "https://a.app.qq.com/o/simple.jsp?pkgname=com.dengtacj.stock&ckey=CK1332521520004";
        }
      }
      return result;
    },
    //mix关闭
    mixClose() {
      // console.log("mixClose");
      const isEnd = this.player.duration() == this.player.currentTime();
      if (this.isIframeVisible) {
        this.isIframeVisible = false;
        this.iframeUrl = "";
      } else if (this.isImageVisible) {
        this.isImageVisible = false;
        this.imageUrl = "";
      }
      this.isBranchStoped = false;
      this.isImageVisible = false;
      this.isMixVisible = false;
      this.isMixClosed = true;
      if (!isEnd) {
        this.player.play();
      }
    },

    //初始化无界面音频播放器
    initAudio5js() {
      this.audio5js = new Audio5js({
        throw_errors: true,
        format_time: true,
      });
    },
    //播放声音
    playSound(soundType) {
      return;
      const soundObj = {
        signup: "sound/signup.mp3",
        vote: "sound/vote.mp3",
        iframe: "sound/iframe.mp3",
        goods: "sound/goods.mp3",
      };
      this.audio5js.load(soundObj[soundType]);
      this.audio5js.play();
      Notify({ type: "warning", message: "play" });
    },
    initBackButton(){
      beacon.setWebViewTitleBar(playerArray, () => {});
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        beacon.getAccountInfo({}, (accountInfo) => {
          this.stUserInfo = accountInfo
          resolve(accountInfo);
        });
      });
    },
  },
  mounted() {
    // var vConsole = new VConsole();
    if (this.optionsIndex == 0) {
      this.initBackButton();
    }
    //初始化音频播放器
    // this.initAudio5js();

    //处理传过来的参数
    this.handleVideoOptions();

    //初始化播放器
    // this.initVideoPlayer();
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style lang="less">
@import "VideoPlayer.less";
</style>
