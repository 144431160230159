<template>
    <div class="interact_card">
      <transition-group name="fade" mode="out-in">
        <div key="trigger" class="card_trigger" @click="openCard"></div>
        <div key="content" class="card_box" v-if="isCardExpanded">
          <!-- 通过是否有微信二维码区分不同的名片显示方式 -->
          <div class="card_box_1" v-if="cardSettings.code">
            <div class="headerImg">
                <img :src="cardSettings.corpLogo" alt="头像">
            </div>
            <h3>{{ cardSettings.name }}</h3>
            <span>{{ cardSettings.title }}</span>
            <div class="qrCodeImg">
                <img :src="cardSettings.code" alt="">
            </div>
            <p>{{ cardSettings.corpName }}</p>
            <div class="iphone">
                <span>{{ cardSettings.mobile }}</span>
                <img src="@/assets/images/interact_video/ico_tel.png" alt="" @click="callPhone(cardSettings.mobile)">
            </div>
          </div>
          <div v-else class="card_box_2">
            <div class="headerImg">
              <img :src="cardSettings.corpLogo" alt="头像">
            </div>
            <h3>{{ cardSettings.name }}</h3>
            <span>{{ cardSettings.title }}</span>
            <p>{{ cardSettings.corpName }}</p>
            <div class="iphone">
              <span>{{ cardSettings.mobile }}</span>
              <img src="@/assets/images/interact_video/ico_tel.png" alt="" @click="callPhone(cardSettings.mobile)">
            </div>
          </div>
        </div>
      </transition-group>
    </div>
</template>
<script>
export default {
    props: {
        cardSettings: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        video: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
        stUserInfo: {
            type: Object,
            required: true,
            default: function() {
                return {}
            }
        },
    },
    data: function() {
        return {
            isCardExpanded: false,
        }
    },
    methods: {
        // 显示|隐藏名片
        openCard() {
            // 只记录名片被展开，收起不记录
            if (!this.isCardExpanded) {
                this.pointCard(1)
            }
            this.isCardExpanded = !this.isCardExpanded
        },
        // 拨打电话
        callPhone(telNumber) {
            this.pointCard(2)
            window.location.href = `tel:${telNumber}`;
        },
        // 名片埋点
        pointCard(type) {
            const { id, name, mobile } = this.cardSettings
            // 只有登陆后才记录埋点
            if (this.stUserInfo.iAccountId) {
                // 1.点击名片 2.点击电话 3.点击微信
                this.API.dataStatistics.businessCardBuriedPoint({
                  videoId: this.video.iId,
                  userId: this.stUserInfo.iAccountId,
                  businessCardId: id,
                  businessCardName: name,
                  operation: type,
                  telephone: type === 2 ? mobile : undefined,
                })
            }
        },
    }
}
</script>
<style lang="less" scoped>
.interact_card {
    position: absolute;
    right: 10px;
    top: 12vh;
    z-index: 7;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    .card_trigger {
      display: block;
      cursor: pointer;
      width: 40px;
      height: 32px;
      background: url('../../../../assets/images/interact_video/ico_card.png');
      background-size: 40px auto;
    }

    .card_box {
      position: absolute;
      right: 0;
      .card_box_1 {
        width: 200px;
        height: 236px;
        padding: 0 12px 14px;
        background: #fff;
        opacity: 0.93;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 8px;
        color: #252525;
        .headerImg {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: #f5f8ff;
          border: 1px solid #252525;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -24px;
          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        > h3 {
          margin: 4px 0;
        }
        .qrCodeImg {
          width: 80px;
          height: 80px;
          overflow: hidden;
          background: #f5f8ff;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0 14px 0;
          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        > p {
          align-self: flex-start;
          font-size: 12px;
          margin-bottom: 10px;
        }
        .iphone {
          align-self: flex-start;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          img {
            width: 24px;
          }
        }
      }
      .card_box_2 {
        margin-top: 8px;
        width: 228px;
        height: 120px;
        position: relative;
        background: #4046E6;
        opacity: 0.93;
        border-radius: 6px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        color: #fff;
        .headerImg {
          position: absolute;
          top: 12px;
          right: 12px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background: #f5f8ff;
          border: 1px solid #252525;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          > img {
            max-width: 100%;
            max-height: 100%;
          }
        }
        > p {
          margin-top: 10px;
          font-size: 12px;
        }
        .iphone {
          align-self: flex-start;
          font-size: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          img {
            width: 24px;
          }
        }
      }
    }
  }
</style>
